<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getDailyReport"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-center">
            <h2 id="dailyTitle" class="mb-0 font-weight-normal">
              {{ $t('report.txtdaily') }}
            </h2>
            <HelpButton :isHeader="false" class="ml-2"></HelpButton>
            </div>
            <div 
              v-if="isExport"
              class="col-md-2 col-4 col-sm-3 text-right text-success">
              <CButton id="dailyExportButton" block color="info" v-on:click="exportDailyReport()"  
              >
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
            </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <hr />
              <div style="overflow-x:auto;">
                <DataTable
                id="dailyDatatable"
                style="white-space: nowrap;"
                :items="items()"
                :fields="fields"
                :loading="loading"
                hover
                border
                clickableRows
                @row-clicked="rowClicked"
               >
                <template #discountAmount="{ item }">
                    <td v-if="item.discountAmount > 0.00" class="text-right text-danger">-{{ item.discountAmount }}</td>
                    <td v-else class="text-right text-dark">{{ item.discountAmount }}</td>
                </template>
              </DataTable>
              </div>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getDailyReport">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import HelpButton from '../../containers/HelpButton.vue'

export default {
  components: {
    Pagination,
    DataTable,
    HelpButton
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        {
          key: 'date',
          label: this.$i18n.t('date'),
          _classes: 'text-left text-dark font-weight-normal ',
        },
        {
          key: 'netAmount',
          label: this.$i18n.t('netAmount'),
          _classes: 'text-right text-dark font-weight-normal ',
        },
        {
          key: 'discountAmount',
          label: this.$i18n.t('discount'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'vatAmount',
          label: this.$i18n.t('vatAmount'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'serviceCharge',
          label: this.$i18n.t('serviceCharge'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'netProfit',
          label: this.$i18n.t('profit'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'grandTotal',
          label: this.$i18n.t('grandTotal'),
          _classes: 'text-right text-dark font-weight-normal',
        },
      ]
    },
    
  },
  created() {
    this.getDailyReport()
  },
  methods: {
    ...util,
    items() {
      let data = this.data
      let detail = []
      let grandTotal = ''
      let discountTotal = 0
      let vatAmount = ''
      let netAmount = ''
      let serviceCharge = ''

      for (let i = 0; i < data.length; i++) {
        if (data[i].grandTotal === undefined) {
          grandTotal = '0.00'
        } else {
          grandTotal = util.convertCurrency(data[i].grandTotal)
        }

        let discount = data[i].discountTotal
        if (discount === undefined) {
          discountTotal = 0
        } else {
          discountTotal = util.convertCurrency(data[i].discountTotal)
        }

        if (data[i].vatAmount === undefined) {
          vatAmount = '0.00'
        } else {
          vatAmount = util.convertCurrency(data[i].vatAmount)
        }

        if (data[i].netAmount === undefined) {
          netAmount = '0.00'
        } else {
          netAmount = util.convertCurrency(data[i].netAmount)
        }

        if (data[i].serviceCharge === undefined) {
          serviceCharge = '0.00'
        } else {
          serviceCharge = util.convertCurrency(data[i].serviceCharge)
        }
        let netProfit = 0
          if (data[i].list !== undefined && Array.isArray(data[i].list)) {
            data[i].list.forEach(item => {
          if (item.netProfit !== undefined) {
            netProfit += item.netProfit
          }
        })
          netProfit = util.convertCurrency(netProfit)
        } else {
          netProfit = '0.00'
        }

        detail.push({
          date: moment(String(data[i].date)).format('DD/MM/YYYY'),
          dateString: data[i].date,
          grandTotal: grandTotal,
          discountAmount: discountTotal,
          vatAmount: vatAmount,
          serviceCharge: serviceCharge,
          netAmount: netAmount,
          netProfit: netProfit
        })
      }
      return detail
    },
    rowClicked(item) {
      this.$router.push(
        '/report/daily/' + this.shopObjectId + '/' + item.dateString
      )
    },
    getDailyReport(page = 1) {
      this.loadingButton = false
      const uid = this.uid
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      this.loading = true
      axios({
        url: '/receipt/v1.0/productselldailygroupreport/' + uid + '/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data.list
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          // this.meta_data.prev_page_url = res.data.paginate.currentPage - 1
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportDailyReport() {
      const uid = this.uid
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      report({
        url: '/receipt/v1.0/productselldailyreport/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานยอดขายสินค้าประจำวัน.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
